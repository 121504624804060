<template>
  <div>
    <TenantAlert />
    <AdminReservationSpotSelection
      heading="Add Reservation"
      :reservationLocationId="locationId"
    />
  </div>
</template>

<script>
import AdminReservationSpotSelection from "@/components/admin/AdminReservationSpotSelection.vue";
import TenantAlert from "@/components/alert/TenantAlert.vue";

export default {
  name: "AdminReservationAdd",
  title: "Admin - Reservation Add",
  components: { AdminReservationSpotSelection, TenantAlert },
  props: {
    locationId: {
      type: String,
      default: null
    }
  },
  methods: {
    initialize() {
      this.$store.dispatch("search/clear");
      const reservation = this.$store.getters["transaction/reservation"];

      this.$store.commit("transaction/setReservation", {
        id: reservation.id,
        lockCode: reservation.lockCode
      });
      if (this.$route.query.arrivalDate) {
        this.$store.commit("search/setSearchDate", {
          key: "startDate",
          value: this.$route.query.arrivalDate
        });
      }
      this.$store.commit("search/setSearchDate", {
        key: "endDate",
        value: null
      });
      this.$store.commit("search/setSelectedSpotId", null);
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style></style>
